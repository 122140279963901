import React from "react";
import ReactDOM from "react-dom/client";
import Layout from "./Layout";
import Login from "./Login";
import WheelGame from "./WheelGame";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminLogin from "./AdminLogin";
import AdminDashboard from "./AdminDashboard";

function App() {
  return (
    <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Layout />}> */}
            <Route path="login" element={<div className="App">
              <div className="App-container"><Login/></div></div>} />
            <Route index element={<div className="App">
              <div className="App-container"><WheelGame/></div></div>} />
          {/* </Route> */}
          <Route path="/admin">
              <Route index element={<AdminLogin/>} />
              <Route path="dashboard" element={<AdminDashboard />} />
          </Route>
        </Routes>
    </BrowserRouter>
 

        
      
  );
}

export default App;
