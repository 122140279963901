import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

function AdminDashboard() {
    const navigate = useNavigate();
    const location = useLocation();

    const token = localStorage.getItem("jwt_token");

    const [stocks,setStocks] = useState({
        cutie: 15,
        tricou: 8,
        papi: 10000,
        sugus: 10000,
    })
    const [probability,setProbability] = useState({
        cutie: 1,
        tricou: 2,
        papi: 50,
        sugus: 50,
    })

    const handleProbsChange = (e) =>{
        const {name,value} = e.target;

        setProbability(prev=>({...prev,[name]:value}));
    }

    const fetchData = async ()=>{
        const response = await fetch("/api/v1/backend/data");

        if(response.ok){
            const body = await response.json();
            console.log(body);
            const newStocks = {
                cutie: 0,
                tricou: 0,
                papi: 0,
                sugus: 0,
            };
            const newProbs = {
                cutie: 0,
                tricou: 0,
                papi: 0,
                sugus: 0,
            };

            body?.prizes.forEach(element => {
                newProbs[element.name] = element.probability *100;
                newStocks[element.name] = element.actual_quantity ;
            });
            setStocks(newStocks);
            setProbability(newProbs);

        }
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('cutie',probability.cutie);
        formData.append('tricou',probability.tricou);
        formData.append('papi',probability.papi);
        formData.append('sugus',probability.sugus);

        const response = await fetch("/api/v1/backend/probability",{method:"POST",body:formData});

        if(response.ok){
            fetchData();
        }
    }

    useEffect(()=>{
        fetchData();

    },[]);

    if (!token) {
        return <Navigate to="/admin" />;
      } 
  return (
    <div className='admin'>
        <h1>Dashboard</h1>

        <div>
            <h2>Probabilitati si stoc</h2>
            <form onSubmit={handleFormSubmit}>
                <div className='stock-form-row'>
                    <div className='stock-row-1'>Produs</div>
                    <div className='stock-row-2'>Stoc</div>
                    <div className='stock-row-3'>Probabilitate (%)</div>
                </div>
                <div className='stock-form-row'>
                    <div className='stock-row-1'>Cutiuță cu bomboane Sugus</div>
                    <div className='stock-row-2'>{stocks.cutie} / 15</div>
                    <div className='stock-row-3'><input type='number' step="1" min={0} max={100} name='cutie'  onChange={handleProbsChange} value={probability.cutie}/></div>
                </div>
                <div className='stock-form-row'>
                    <div className='stock-row-1'>Tricou</div>
                    <div className='stock-row-2'>{stocks.tricou} / 8</div>
                    <div className='stock-row-3'><input type='number' step="1" min={0} max={100} name='tricou' onChange={handleProbsChange} value={probability.tricou} /></div>
                </div>
                <div className='stock-form-row'>
                    <div className='stock-row-1'>Prajiturică Papi</div>
                    <div className='stock-row-2'>{stocks.papi} / 10000</div>
                    <div className='stock-row-3'><input type='number' step="1" min={0} max={100} name='papi' onChange={handleProbsChange} value={probability.papi} /></div>
                </div>
                <div className='stock-form-row'>
                    <div className='stock-row-1'>Sugus Viermișori Acrișori</div>
                    <div className='stock-row-2'>{stocks.sugus} / 10000</div>
                    <div className='stock-row-3'><input type='number' step="1" min={0} max={100} name='sugus' onChange={handleProbsChange} value={probability.sugus}/></div>
                </div>
                <button className='form-save-btn'>Salveaza</button>
            </form>
        </div>
    </div>
  )
}

export default AdminDashboard